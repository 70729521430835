import type { FC, InputHTMLAttributes } from "react"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label?: string | null
}

const Checkbox: FC<Props> = ({ id, label, ...props }) => {
  return (
    <div className="mb-4 flex items-center">
      <input
        {...props}
        id={id}
        name={id}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-primary-800 focus:ring-amber-200"
      />
      {label ? (
        <label htmlFor={id} className="ml-3 block text-sm leading-6">
          {label}
        </label>
      ) : null}
    </div>
  )
}

export default Checkbox
