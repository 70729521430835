import { useEffect, useState } from "react"
import {
  json,
  redirect,
  type LoaderFunctionArgs,
  type ActionFunctionArgs,
} from "@remix-run/node"
import {
  Form,
  Link,
  useActionData,
  useSearchParams,
  useNavigation,
  type MetaFunction,
} from "@remix-run/react"
import { useTranslation } from "react-i18next"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid"
import dayjs from "dayjs"

import { LOGIN } from "~/graphql/queries/login.server"
import { validateEmail } from "~/utils/validation"
import {
  createUserSession,
  getUserId,
  safeRedirect,
} from "~/utils/session.server"
import { fetchFromGraphQL, parseGraphQLErrorCode } from "~/graphql/utils.server"
import TextField from "~/components/TextField"
import Checkbox from "~/components/Checkbox"
import config from "~/appconfig"
import PoweredByFielder from "~/components/PoweredByFielder"
import { Spinner } from "~/components/icons/Spinner"
import { getDailySideImageBgUrlClassName } from "~/utils/rendering.server"

export const meta: MetaFunction = () => {
  return [
    { title: "Fielder | Login" },
    { name: "description", content: "Welcome to Fielder!" },
  ]
}

const namespace = ["login", "apiError"]
export let handle = {
  // In the handle export, we can add an i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: namespace,
}

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await getUserId(request)
  if (userId) {
    return redirect("/dashboard")
  }

  const dayOfWeek = dayjs().day()
  let sideImageBgUrlClassName = getDailySideImageBgUrlClassName(dayOfWeek)
  return json({ sideImageBgUrlClassName })
}

/**
 * The action function is called when the user submits the form. It runs on the server only.
 * It validates the form inputs and, if they are good, sends the data to the GraphQL API.
 * If the GraphQL API returns an error, we return that error to the client. Otherwise,
 * if it responds with user data, we create a session for the user and redirect them to
 * wherever they wanted to go initially, or to the dashboard route by default.
 */
export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData()
  const username = formData.get("username")
  const password = formData.get("password")
  const redirectTo = safeRedirect(formData.get("redirectTo"), "/dashboard")
  const remember = formData.get("stay-signed-in")

  if (!validateEmail(username)) {
    return json(
      {
        validationErrors: {
          username: "email.invalid",
          password: null,
        },
        apiErrors: null,
        timestamp: Date.now(),
      },
      { status: 400 },
    )
  }

  if (typeof password !== "string" || password.length === 0) {
    return json(
      {
        validationErrors: {
          username: null,
          password: "password.required",
        },
        apiErrors: null,
        timestamp: Date.now(),
      },
      { status: 400 },
    )
  }

  const responseJson = await fetchFromGraphQL(LOGIN, { username, password })
  const authToken = responseJson?.data?.login?.authToken
  const user = responseJson?.data?.login?.user

  if (responseJson.errors || !user || !authToken) {
    const errorCodes = parseGraphQLErrorCode(responseJson.errors)

    return json(
      {
        validationErrors: null,
        apiErrors: errorCodes,
        timestamp: Date.now(),
      },
      { status: 400 },
    )
  } else {
    return createUserSession({
      redirectTo,
      remember: remember === "on" ? true : false,
      request,
      authToken,
      user,
    })
  }
}

export default function Login() {
  const { t } = useTranslation(namespace)
  const apiErrorNS = (s: string) => t(s, { ns: "apiError" })
  const navigation = useNavigation()
  const actionData = useActionData<typeof action>()
  const apiError = actionData?.apiErrors?.[0]
  const [searchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState(false)
  const [username, setUsername] = useState("")
  const [showValidationAnimation, setShowValidationAnimation] = useState(
    Boolean(apiError),
  )

  useEffect(() => {
    setShowValidationAnimation(Boolean(apiError))
  }, [actionData?.timestamp, apiError])

  return (
    <div className="mt-4 flex flex-col sm:mx-auto sm:w-full sm:max-w-sm lg:mt-10">
      {apiError ? (
        <div className="rounded-md bg-yellow-400 p-4">
          <div className="align-center flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-700"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-normal text-yellow-900">
                {apiErrorNS(apiError)}
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <Form className="space-y-9" method="post">
        <input
          type="hidden"
          name="redirectTo"
          value={searchParams.get("redirectTo") ?? "/dashboard"}
        />
        <TextField
          autoFocus
          id="username"
          name="username"
          type="email"
          autoComplete="username email"
          label={t("username")}
          onChange={(e) => setUsername(e.target.value)}
          required
          tabIndex={1}
          value={username}
        />
        <TextField
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          label={t("password")}
          headerAction={
            <Link
              to={`/forgot-password?email=${encodeURIComponent(username)}`}
              className="link-primary-500 hover:link-primary-300 text-sm underline"
              prefetch="intent"
              tabIndex={3}
            >
              {t("forgotPassword")}
            </Link>
          }
          required
          rightIcon={
            <button
              type="button"
              className="h-full rounded-md border-0 bg-transparent px-2 py-0 text-gray-500 hover:bg-slate-100 sm:text-sm"
              onClick={(e) => {
                e.preventDefault()
                setShowPassword((val) => !val)
              }}
              title={showPassword ? t("hidePassword") : t("showPassword")}
            >
              {showPassword ? (
                <EyeSlashIcon
                  className="text-grey-900 h-5 w-5"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="text-grey-900 h-5 w-5" aria-hidden="true" />
              )}
            </button>
          }
          tabIndex={2}
        />
        <Checkbox id="stay-signed-in" label={t("staySignedIn")} tabIndex={4} />
        <div>
          <button
            disabled={navigation.state === "submitting"}
            type="submit"
            tabIndex={5}
            className={`flex min-h-max w-full justify-center rounded-md bg-btn-primary-500 px-3 py-1.5 text-sm font-normal leading-6 tracking-wide text-white shadow-sm hover:bg-btn-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-300 ${
              showValidationAnimation ? "animate-shake" : ""
            }`}
            onAnimationEnd={() => setShowValidationAnimation(false)}
          >
            {navigation.state === "submitting" ? (
              <div className="p-0.5">
                <Spinner />
              </div>
            ) : (
              t("signIn")
            )}
          </button>
        </div>
      </Form>
      {config.customer === "default" ? (
        <div className="mt-8 flex flex-row justify-center gap-2 align-baseline text-sm">
          <span>{t("noAccountPrompt")}</span>
          <Link
            to="/signup/pricing"
            className="link-primary-500 hover:link-primary-300 text-sm font-semibold underline"
          >
            {t("signUp")}
          </Link>
        </div>
      ) : (
        <div className="mt-20">
          <PoweredByFielder />
        </div>
      )}
    </div>
  )
}
